.bottomMenu_1{
    font-family: 'Space Grotesk';
    position: fixed;
    bottom: 0;
    text-align: center;
    color: black;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    font-size: 15px;
    background-color: white;
}