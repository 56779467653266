.TextField, .TextField:focus, .TextField:active {
    color: black;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #C8DDE1;
    outline: none;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    width: 96%;
    max-width: 280px !important;
    margin: 20;
}
.TextField[type=number]::-webkit-inner-spin-button, 
.TextField[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}