@font-face {
  font-family: 'Space Grotesk';
  src: url('fonts/SpaceGrotesk-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Rubik Mono One';
  src: url('fonts/RubikMonoOne.ttf') format('truetype');
}
body {
  font-family: 'Space Grotesk';
  margin: 0;
  height: 100vh;
  padding: 0;
  color: #313A56;
}
img{
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header{
  height: 0.25vh;
}